
import { defineComponent, PropType } from "vue";
import { MdCard } from "@/components/md/MdCard";
import { IPlan } from "@/store/types";
import { useStore } from "@/store";
import { AdminStore } from "@/store/constants";

export default defineComponent({
  name: "Plan",
  components: {
    MdCard
  },
  props: {
    planName: {
      type: String as PropType<"startup" | "business" | "premium">,
      required: true
    },
    row: Boolean
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    plan(): IPlan {
      return this.store.getters[AdminStore.Getters.GET_PLAN](this.planName);
    },
    euros(): string {
      return Math.floor(this.plan.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    cents(): string {
      const splitted = (this.plan.price || 0).toString().split(".");
      if(splitted.length > 1) return ("0" + splitted[1]).slice(-2);
      return "00";
    },
    interactions(): string {
      return (this.plan.interactions || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
});
