
import { defineComponent } from "vue";
import { ContentLoader } from "vue-content-loader";
import { MdCard } from "@/components/md/MdCard";

export default defineComponent({
  name: "PaymentMethodLoader",
  components: {
    ContentLoader,
    MdCard
  }
});
