
import { defineComponent } from "vue";
import { MdRadio } from "@/components/md/MdRadio";
import Visalogo from "@/assets/images/payment-methods/visa.svg";
import MastercardLogo from "@/assets/images/payment-methods/mastercard.svg";
import Amexlogo from "@/assets/images/payment-methods/amex.svg";
import Sepalogo from "@/assets/images/payment-methods/sepa.svg";
import Cardlogo from "@/assets/images/payment-methods/card.svg";
import { IPaymentMethodResult } from "@chatcaptain/types/billing";
import { useStore } from "@/store";
import { AdminStore } from "@/store/constants";

export default defineComponent({
  name: "PaymentMethodSelector",
  components: {
    MdRadio
  },
  emits: ["select"],
  setup() {
    const store = useStore();
    store.dispatch(AdminStore.Actions.LOAD_PAYMENT_METHODS);
    return { store };
  },
  data() {
    return {
      paymentMethodId: "",
      logos: {
        visa: Visalogo,
        amex: Amexlogo,
        mastercard: MastercardLogo,
        sepa: Sepalogo,
        card: Cardlogo
      }
    }
  },
  methods: {
    getLogo(brand: string): string | null {
      if(this.logos[brand]) return this.logos[brand];
      return this.logos.card;
    }
  },
  computed: {
    methods(): IPaymentMethodResult[] {
      return this.store.getters[AdminStore.Getters.GET_PAYMENT_METHODS];
    }
  },
  watch: {
    methods(val: IPaymentMethodResult[]) {
      if(val.length > 0) this.paymentMethodId = val[0].id;
    },
    paymentMethodId(val) {
      this.$emit("select", val);
    }
  }
});
