
import { defineComponent, PropType } from "vue";
import { MdCard } from "@/components/md/MdCard";
import { MdButton } from "@/components/md/MdButton";
import { IPaymentMethodResult } from "@chatcaptain/types/billing"
import Visalogo from "@/assets/images/payment-methods/visa.svg";
import Mastercardlogo from "@/assets/images/payment-methods/mastercard.svg";
import Amexlogo from "@/assets/images/payment-methods/amex.svg";
import Sepalogo from "@/assets/images/payment-methods/sepa.svg";
import Cardlogo from "@/assets/images/payment-methods/card.svg";

export default defineComponent({
  name: "PaymentMethod",
  components: {
    MdCard,
    MdButton
  },
  props: {
    method: {
      type: Object as PropType<IPaymentMethodResult>,
      required: true
    }
  },
  emits: ["click-delete", "click-edit"],
  data() {
    return {
      logos: {
        visa: Visalogo,
        amex: Amexlogo,
        mastercard: Mastercardlogo,
        sepa: Sepalogo,
        card: Cardlogo
      }
    }
  },
  methods: {
    getLogo(brand: string): string | null {
      if(this.logos[brand]) return this.logos[brand];
      return this.logos.card;
    }
  },
  computed: {
    expire(): string {
      if(this.method.card){
        const date = new Date(this.method.card.expire);
        return ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
      }
      return "";
    }
  },
});
