
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { MdCheckbox } from "@/components/md/MdCheckbox";
import { useStore } from "@/store";
import { AdminStore } from "@/store/constants";
import { IChatbotResult } from "@/api/bot";

export default defineComponent({
  name: "UpdatePermissionsModal",
  mixins: [MdModalComponentMixin],
  components: { 
    MdModal,
    MdButton,
    MdCheckbox
  },
  props: {
    permissions: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      group: "",
      error: "",
      groupModalLoading: true,
      grantedPermissions: Array.from(this.permissions),
      grantedPermissionsCopy: Array.from(this.permissions),
      grantedChatbotPermissions: [] as string[]
    }
  },
  computed: {
    chatbots(): IChatbotResult[] {
      return this.store.getters[AdminStore.Getters.GET_CHATBOTS];
    }
  },
  watch: {
    permissions() {
      this.grantedPermissions = Array.from(this.permissions);
      this.updateChatbotPermissions();
    },
    chatbots() {
      this.groupModalLoading = false;
      this.updateChatbotPermissions();
    },
    grantedPermissions: {
      deep: true,
      handler(value: string[]){
        this.grantedPermissionsCopy = Array.from(value);
      }
    },
    grantedPermissionsCopy(newValue: string[], oldValue: string[]) {
      if(newValue.includes("cc.chatbots.any") && !oldValue.includes("cc.chatbots.any") && !newValue.includes("MODIFIED")){ //activate access to all chatbots
        this.chatbots.forEach(chatbot => {
          const permission = "cc.chatbots." + chatbot.id;
          if(!this.grantedPermissions.includes(permission)) this.grantedPermissions.push(permission);
        });
      } else if(!newValue.includes("cc.chatbots.any") && oldValue.includes("cc.chatbots.any") && !newValue.includes("MODIFIED")){ //deactivate access to all chatbots
        this.grantedPermissions = this.grantedPermissions.filter(el => !el.startsWith("cc.chatbots"));
      } else { //check if one chatbot was unchecked and disable the checkbox for all chatbots
        const difference = oldValue.filter(el => !newValue.includes(el));
        if(difference.length > 0 && difference[0].startsWith("cc.chatbots.")){
          const newArray = this.grantedPermissions.filter(el => el != "cc.chatbots.any");
          newArray.push("MODIFIED");
          this.grantedPermissions = newArray;
        }
      }
      if(newValue.includes("MODIFIED")) this.grantedPermissions = newValue.filter(el => el != "MODIFIED");
    }
  },
  methods: {
    updateChatbotPermissions() {
      if(this.grantedPermissions.includes("cc.chatbots.any")){
        this.chatbots.forEach(chatbot => {
          const permission = "cc.chatbots." + chatbot.id;
          if(!this.grantedPermissions.includes(permission)) this.grantedPermissions.push(permission);
        });
      }
    },
    submit() {
      let permissions: string[] = this.grantedPermissions.filter(el => !el.startsWith("cc.chatbots."));
      if(this.grantedPermissions.includes("cc.chatbots.any")){
        permissions.push("cc.chatbots.any");
      } else {
        permissions = permissions.concat(this.grantedPermissions.filter(el => el.startsWith("cc.chatbots.")));
      }
      this.$emit("submit", permissions);
    }
  }
});
