
import { defineComponent, PropType } from 'vue';
import { MdButton } from '@/components/md/MdButton';
import { MdOverlayContentMixin } from "@/components/md/MdOverlay";
import { MdInputField } from "@/components/md/MdInput";
import { MdTooltip } from "@/components/md/MdTooltip";
import Plan from "@/components/admin/Plan.vue";
import { Stepper, Step, IStepper } from "@/components/cc/Stepper";
import PaymentMethodSelector from "@/components/admin/PaymentMethodSelector.vue";
import { IPromotionResult, ISubscriptionResult, IUpcomingInvoiceResult } from "@chatcaptain/types/billing";
import { createSubscription, getPromotionCode, getUpcomingInvoice } from '@/api/admin/billing';
import BillingChangeSubscriptionSumLoader from "@/components/admin/BillingChangeSubscriptionSumLoader.vue";
import { useStore } from '@/store';
import { AdminStore, RootStore } from '@/store/constants';

export default defineComponent({
  name: "UsersInviteOverlay",
  mixins: [MdOverlayContentMixin],
  components: {
    Plan,
    MdButton,
    Stepper,
    Step,
    PaymentMethodSelector,
    MdInputField,
    MdTooltip,
    BillingChangeSubscriptionSumLoader
  },
  props: {
    subscription: Object as PropType<ISubscriptionResult>
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      plans: ["startup", "business", "enterprise"],
      plan: "",
      promoCode: "",
      promoCodeError: "",
      promo: null as IPromotionResult | null,
      paymentMethodId: "",
      invoice: null as IUpcomingInvoiceResult | null
    }
  },
  mounted() {
    this.getInvoice();
  },
  watch: {
    plan() {
      this.getInvoice();
    },
    paymentMethodId() {
      this.getInvoice();
    }
  },
  methods: {
    selectPlan(planName: string) {
      this.plan = planName;
      (this.$refs.stepper as IStepper).next();
    },
    async promoCodeKeyDown(event: KeyboardEvent) {
      if(event.key == "Enter"){
        const promotion = await getPromotionCode(this.promoCode);
        if(promotion.active === true){
          this.promo = promotion;
          this.promoCodeError = "";
          this.getInvoice();
        } else {
          this.promoCodeError = "Dieser Gutscheincode ist nicht gültig.";
        }
      }
    },
    deletePromoCode() {
      this.promoCode = "";
      this.promo = null;
      this.getInvoice();
    },
    numberWithDots(value): string {
      return (value / 100).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getInvoice() {
      if(this.plan && this.plan.length > 0){
        this.invoice = null;
        this.invoice = await getUpcomingInvoice(this.plan, this.paymentMethodId, this.promo ? this.promoCode : undefined);
      } 
    },
    async submitSubscription() {
      this.setLoading(true);
      try {
        const id = await createSubscription(this.plan, this.paymentMethodId, this.promoCode);
        this.store.dispatch(AdminStore.Actions.LOAD_SUBSCRIPTION);
        setTimeout(() => {
          this.store.dispatch(AdminStore.Actions.LOAD_SUBSCRIPTION);
        }, 2000);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Abonnement erstellt"
        });
        this.complete();
        this.close();
        this.setLoading(false);
      } catch(err) {
        console.error('error: ', err);
        this.setLoading(false);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Es ist ein unbekannter Fehler aufgetreten"
        });
      }
    }
  },
});
