
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { IUserResult } from "@chatcaptain/types/organization";
import { MdSelect } from "@/components/md/MdInput";
import { IMenuItem } from "@/components/md/MdMenu";
import { useStore } from "@/store";
import { AdminStore, RootStore } from "@/store/constants";
import { InputType, InputValidator } from "@/classes/InputValidator";
import { addMember } from "@/api/admin/groups";
import { ISnackbarData } from "../md/MdSnackbar";

export default defineComponent({
  name: "UserAddGroupModal",
  mixins: [MdModalComponentMixin],
  components: { 
    MdModal,
    MdSelect,
    MdButton,
  },
  props: {
    user: {
      type: Object as PropType<IUserResult>,
      required: true
    }
  },
  setup() {
    const store = useStore();
    store.dispatch(AdminStore.Actions.LOAD_GROUPS);
    return { store };
  },
  data() {
    return {
      group: "",
      error: "",
      groupModalLoading: false
    }
  },
  computed: {
    groups(): IMenuItem[] {
      const items: IMenuItem[] = [];
      const groups = this.store.getters[AdminStore.Getters.GET_GROUPS];
      if(groups) groups.forEach(group => {
        items.push({
          label: group.name,
          value: group.id
        });
      });
      return items;
    },
    inputValidator(): InputValidator {
      return new InputValidator(this.group, InputType.TEXT, true);
    }
  },
  methods: {
    async addToGroup() {
      if(!this.inputValidator.isValid()){
        this.error = "Du musst eine Gruppe auswählen";
        return;
      }
      this.groupModalLoading = true;
      await addMember(this.group, this.user.id);
      const group = this.store.getters[AdminStore.Getters.GET_GROUP](this.group);
      if(group){
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Benutzer zu " + group.name + " hinzugefügt"
        } as ISnackbarData);
      }
      this.groupModalLoading = false;
      this.close();
      this.group = "";
      this.$emit("submit");
    }
  }
});
