
import { defineComponent } from "vue";
import { MdTabs } from "@/components/md/MdTabs";
import { MdFabExtended } from "@/components/md/MdFab";
import ChatbotCard from "@/components/admin/ChatbotCard.vue";
import ChatbotCardLoader from "@/components/admin/ChatbotCardLoader.vue";
import { useStore } from "@/store";
import { AdminStore } from "@/store/constants";
import { IChatbotResult } from "@/api/bot";

export default defineComponent({
  name: "AdminChatbots",
  components: {
    MdTabs,
    MdFabExtended,
    ChatbotCard,
    ChatbotCardLoader,
  },
  setup() {
    const store = useStore();
    store.dispatch(AdminStore.Actions.LOAD_CHATBOTS);
    return { store };
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    chatbots(): IChatbotResult[] {
      return this.store.getters[AdminStore.Getters.GET_CHATBOTS];
    }
  },
  watch: {
    chatbots() {
      this.isLoading = false;
    }
  },
  methods: {
    redirectToChatbot(chatbotId: string) {
      console.log('click');
      window.open("https://app.chatcaptain.com/bot/" + chatbotId, "_blank");
    },
    importBotsTest() {
      console.log('import...');
    }
  },
});
