
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { cancelSubscription } from "@/api/admin/billing";
import { useStore } from "@/store";
import { AdminStore, RootStore } from "@/store/constants";
import { ISubscriptionResult } from "@chatcaptain/types/billing";

export default defineComponent({
  name: "BillingCancelSubscriptionModal",
  mixins: [MdModalComponentMixin],
  components: { 
    MdModal,
    MdButton,
  },
  props: {
    subscription: {
      type: Object as PropType<ISubscriptionResult>,
      required: true
    }
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    endsAt(): string {
      const date = new Date(this.subscription.period_end);
      return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)) + "." + date.getFullYear();
    },
    name(): string {
      return this.store.getters[AdminStore.Getters.GET_PLAN](this.subscription.plan).name;
    }
  },
  methods: {
    async cancelSubscription() {
      this.setLoadingState(true);
      await cancelSubscription();
      this.setLoadingState(false);
      this.close();
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: "Abonnement gekündigt"
      });
      this.store.dispatch(AdminStore.Actions.LOAD_SUBSCRIPTION);
      setTimeout(() => {
        this.store.dispatch(AdminStore.Actions.LOAD_SUBSCRIPTION);
      }, 4000);
    }
  },
});
