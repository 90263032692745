
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { IGroupResult, IUserResult } from "@chatcaptain/types/organization";
import { useStore } from "@/store";
import { RootStore } from "@/store/constants";
import { removeMember } from "@/api/admin/groups";

export default defineComponent({
  name: "UserRemoveFromGroupModal",
  mixins: [MdModalComponentMixin],
  components: { 
    MdModal,
    MdButton,
  },
  props: {
    user: {
      type: Object as PropType<IUserResult>,
      required: true
    },
    group: {
      type: Object as PropType<IGroupResult>,
      required: true
    }
  },
  setup() {
    const store = useStore();
    return { store }
  },
  data() {
    return {
      modalIsLoading: false
    }
  },
  methods: {
    async removeFromGroup() {
      this.modalIsLoading = true;
      await removeMember(this.group.id, this.user.id);
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: this.user.name + " aus " + this.group.name + " entfernt"
      });
      this.modalIsLoading = false;
      this.close();
      this.$emit("submit");
    }
  },
});
