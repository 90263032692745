
import { defineComponent } from "vue";
import MdChipMixin from "./MdChipMixin";

export default defineComponent({
  name: "MdFilterChip",
  mixins: [MdChipMixin],
  props: {
    selected: Boolean,
  },
  emits: ["change"],
  data() {
    return {
      chipIsSelected: this.selected || false,
    };
  },
  beforeMount() {
    this.chipIsSelected = this.selected || false;
  },
  computed: {
    isSelected(): boolean {
      return this.chipIsSelected;
    },
  },
  watch: {
    selected(newValue) {
      this.chipIsSelected = newValue;
    }
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.chipIsSelected = !this.isSelected;
      this.$emit("change", this.isSelected);
    },
  },
});
