
import { defineComponent } from 'vue';
import { MdInputField } from '@/components/md/MdInput';
import { MdButton } from '@/components/md/MdButton';
import { MdInputChip } from '@/components/md/MdChips';
import { InputType, InputValidator } from '@/classes/InputValidator';
import { MdOverlayContentMixin } from "@/components/md/MdOverlay";
import { getOrganizationId, inviteUser } from '@/api/admin/users';
import { useStore } from '@/store';
import { RootStore } from '@/store/constants';

export default defineComponent({
  name: "UsersInviteOverlay",
  mixins: [MdOverlayContentMixin],
  components: {
    MdInputField,
    MdButton,
    MdInputChip
  },
  data() {
    return {
      email: "",
      emails: [] as string[],
      error: ""
    }
  },
  setup() {
    getOrganizationId();
    const store = useStore();
    return { store }
  },
  methods: {
    emailKeydown(event: KeyboardEvent) {
      if(event.key == "Enter") this.addEmailAddress();
    },
    addEmailAddress() {
      const email = this.email.trim();
      if(email && email.length > 0){
        const validator = new InputValidator(this.email, InputType.EMAIL, false);
        if(validator.isValid()){
          this.emails.push(email);
          this.email = "";
          this.error = "";
        } else {
          this.error = "Dies ist keine gültige E-Mail-Adresse";
        }
      }
    }, 
    removeEmailAddress(index: number) {
      this.emails.splice(index, 1);
    },
    async inviteUsers() {
      this.setLoading(true);
      const proms: Promise<void>[] = [];
      this.emails.forEach(email => {
        proms.push(inviteUser(email));
      });
      await Promise.all(proms);
      this.setLoading(false);
      this.close();
      this.complete();
      this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: this.emails.length + " Benutzer eingeladen"
      });
    }
  },
})
