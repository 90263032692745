
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "Step",
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  setup() {
    const selectedIndex = inject("selectedIndex");
    return { selectedIndex };
  }
});
