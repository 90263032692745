
import { defineComponent, PropType } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { IGroupResult } from "@chatcaptain/types/organization";

export default defineComponent({
  name: "GroupDeleteModal",
  mixins: [MdModalComponentMixin],
  components: { 
    MdModal,
    MdButton,
  },
  props: {
    group: {
      type: Object as PropType<IGroupResult>,
      required: true
    }
  }
});
