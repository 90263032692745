import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe5fa1b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment-method-selector" }
const _hoisted_2 = { class: "flex-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_radio = _resolveComponent("md-radio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.methods, (method) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "method",
        key: method.id
      }, [
        _createVNode(_component_md_radio, {
          modelValue: _ctx.paymentMethodId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.paymentMethodId) = $event)),
          value: method.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.getLogo(method.card?.brand))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "logo",
                    style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.getLogo(method.card?.brand || 'sepa') + ')' })
                  }, null, 4))
                : _createCommentVNode("", true),
              _createElementVNode("h2", null, "**** **** **** **** " + _toDisplayString(method.card?.last4 || method.sepa?.last4), 1)
            ]),
            _createElementVNode("h3", null, _toDisplayString(method.holderName), 1)
          ]),
          _: 2
        }, 1032, ["modelValue", "value"])
      ]))
    }), 128))
  ]))
}