
import { defineComponent } from "vue";
import { EmptyHint } from "@/components/cc/EmptyHint";
import { MdButton } from "@/components/md/MdButton";
import { MdOverlay } from "@/components/md/MdOverlay";
import { IRow, MdTable, MdTableColumn } from "@/components/md/MdTable";
import { MdCard } from "@/components/md/MdCard";
import Plan from "@/components/admin/Plan.vue";
import BillingChangeSubscriptionOverlay from "@/components/admin/BillingChangeSubscriptionOverlay.vue";
import BillingCancelSubscriptionModal from "@/components/admin/BillingCancelSubscriptionModal.vue";
import BillingReactivateSubscriptionModal from "@/components/admin/BillingReactivateSubscriptionModal.vue";
import Visalogo from "@/assets/images/payment-methods/visa.svg";
import MastercardLogo from "@/assets/images/payment-methods/mastercard.svg";
import Amexlogo from "@/assets/images/payment-methods/amex.svg";
import Sepalogo from "@/assets/images/payment-methods/sepa.svg";
import Cardlogo from "@/assets/images/payment-methods/card.svg";
import { useStore } from "@/store";
import { IEstimatedInvoiceResult, IPaymentMethodResult, ISubscriptionResult } from "@chatcaptain/types/billing";
import { AdminStore } from "@/store/constants";
import BillingSubscriptionMethodLoader from "@/components/admin/BillingSubscriptionMethodLoader.vue";
import PlanLoader from "@/components/admin/PlanLoader.vue";
import BillingSumLoader from "@/components/admin/BillingSubscriptionSumLoader.vue";
import { loadStripe } from "@stripe/stripe-js";
import { getEstimatedInvoice } from "@/api/admin/billing";

export default defineComponent({
  name: "AdminBillingSubscription",
  components: {
    EmptyHint,
    MdButton,
    MdOverlay,
    Plan,
    BillingChangeSubscriptionOverlay,
    BillingCancelSubscriptionModal,
    BillingReactivateSubscriptionModal,
    MdTable,
    MdTableColumn,
    MdCard,
    BillingSubscriptionMethodLoader,
    PlanLoader,
    BillingSumLoader
  },
  setup() {
    const store = useStore();
    return { store };
  },
  async mounted() {
    this.loadSubscription();
    this.estimate = await getEstimatedInvoice();
  },
  data() {
    return {
      estimate: null as IEstimatedInvoiceResult | null,
      changeOverlayVisible: false,
      changeSubscriptionLoading: false,
      logos: {
        visa: Visalogo,
        amex: Amexlogo,
        mastercard: MastercardLogo,
        sepa: Sepalogo,
        card: Cardlogo
      },
      isLoading: true
    }
  },
  methods: {
    getLogo(brand: string): string | null {
      if(this.logos[brand]) return this.logos[brand];
      return this.logos.card;
    },
    loadSubscription() {
      this.store.dispatch(AdminStore.Actions.LOAD_SUBSCRIPTION);
    },
    async verifyCard() {
      const stripe = await loadStripe("pk_test_1wF4Dh1JaYPCwwjYqyAqgaXi");
      const issue = this.subscription?.issue;
      if(issue){
        try {
          const result = await stripe?.confirmCardPayment(issue.client_secret);
          console.log('result: ', result);
        } catch(err) {
          console.log('error: ', err);
        }
      }
    },
    getDate(date: Date) {
      return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)) + "." + date.getFullYear();
    },
    getPrice(price: number): string {
      return (price  /100).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getNumberWithDots(number: number): string {
      return number.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  watch: {
    subscription() {
      this.isLoading = false;
      this.store.dispatch(AdminStore.Actions.LOAD_PAYMENT_METHODS);
    }
  },
  computed: {
    subscription(): ISubscriptionResult | null {
      return this.store.getters[AdminStore.Getters.GET_SUBSCRIPTION];
    },
    method(): IPaymentMethodResult | null {
      const methods = this.store.getters[AdminStore.Getters.GET_PAYMENT_METHODS];
      return methods.find(method => method.id == this.subscription?.payment_method) || null;
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      if(this.estimate){
        this.estimate.lines.forEach((item, index) => {
          rows.push({
            index: index + 1,
            description: item.name,
            period: this.getDate(new Date(item.start)) + " - " + this.getDate(new Date(item.end)),
            amount: item.quantity,
            price: this.getPrice(item.amount) + " €"
          });
        });
      }
      return rows;
    }
  },
});
