
import { defineComponent, ref } from "vue";
import { MdTabs } from "@/components/md/MdTabs";
import { MdTable, MdTableColumn, IRowButton, IRow } from "@/components/md/MdTable";
import { getOrganizationId, getAllGroups, createGroup, deleteGroup } from "@/api/admin/groups";
import { IGroupResult } from "@chatcaptain/types/organization";
import { MdModal, IMdModal } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { MdFabExtended } from "@/components/md/MdFab";
import { MdInputField } from "@/components/md/MdInput";
import { InputType, InputValidator } from "@/classes/InputValidator";
import { useStore } from "vuex";
import { AdminStore, RootStore } from "@/store/constants";
import { EmptyHint } from "@/components/cc/EmptyHint";
import GroupDeleteModal from "@/components/admin/GroupDeleteModal.vue";
import GroupAddMemberModal from "@/components/admin/GroupAddMemberModal.vue";

export default defineComponent({
  name: "AdminGroups",
  components: {
    MdTabs,
    MdTable,
    MdTableColumn,
    MdModal,
    MdButton,
    MdFabExtended,
    MdInputField,
    EmptyHint,
    GroupDeleteModal,
    GroupAddMemberModal
  },
  props: {
    selectable: Boolean
  },
  setup() {
    const store = useStore();
    return { store }
  },
  data() {
    return {
      addMemberButton: {
        icon: "group_add",
        tooltip: "Nutzer hinzufügen"
      } as IRowButton,
      deleteButton: {
        icon: "delete",
        tooltip: "Gruppe löschen"
      } as IRowButton,
      isLoading: true,
      target: {
        index: 0,
        row: null as (IRow | null),
        group: null as (IGroupResult | null)
      },
      newGroupName: "",
      newGroupDescription: "",
      createModalLoading: false,
      currentlyLoading: [] as number[]
    } 
  },
  async created() {
    getOrganizationId();
    this.loadGroups();
  },
  watch: {
    groups() {
      this.currentlyLoading = [];
      this.isLoading = false;
    }
  },
  computed: {
    newGroupNameValidator(): InputValidator {
      return new InputValidator(this.newGroupName, InputType.TEXT, true);
    },
    newGroupNameField(): typeof MdInputField {
      return this.$refs.newGroupName as typeof MdInputField;
    },
    groups(): IGroupResult[] {
      return this.store.getters[AdminStore.Getters.GET_GROUPS];
    },
    rows(): IRow[] {
      const rows = [] as IRow[];
      this.groups.forEach((group: IGroupResult, index: number) => {
        let permissions = "Keine erteilt";
        if(group.permissions.length > 0) permissions = group.permissions.length + " Stück";
        rows.push({
          id: group.id,
          name: group.name,
          description: group.description,
          permissions,
          members: group.members_count,
          add_member: this.addMemberButton,
          delete: this.deleteButton,
          group: group,
          _loading: this.currentlyLoading.includes(index)
        });
      });
      return rows;
    },
    deleteModal(): IMdModal {
      return (this.$refs.deleteModal as IMdModal);
    },
    addMemberModal(): IMdModal {
      return (this.$refs.addMemberModal as IMdModal);
    }
  },
  methods: {
    loadGroups() {
      this.store.dispatch(AdminStore.Actions.LOAD_GROUPS);
    },
    redirectToGroup(row: IRow, index: number) {
      this.$router.push({
        path: "/admin/groups/" + row.id
      });
    },
    handleTableButtonClick(columnName: string, row: IRow, index: number) {
      this.target.index = index;
      this.target.row = row;
      this.target.group = row.group;
      switch (columnName) {
        case "add_member":
          this.addMemberModal.open();
          break;
        case "delete":
          this.deleteModal.open();
          break;
      }
    },
    async deleteGroup() {
      this.deleteModal.close();
      const index = this.target.index;
      if(this.target.row?.id){
        this.currentlyLoading.push(index);
        await deleteGroup(this.target.row.id);
        this.loadGroups();
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
        state: "success",
        content: "Gruppe " + this.target.group?.name + " gelöscht"
      });
      }
    },
    openCreateModal() {
      (this.$refs.createModal as IMdModal).open();
    },
    closeCreateModal() {
      (this.$refs.createModal as IMdModal).close();
    },
    async createGroup() {
      this.newGroupNameField.validate();
      if(!this.newGroupNameValidator.isValid()) return;
      this.createModalLoading = true;
      await createGroup(this.newGroupName, this.newGroupDescription);
      this.newGroupName = "";
      this.newGroupDescription = "";
      this.loadGroups();
      this.createModalLoading = false;
      this.closeCreateModal();
    }
  },
});
