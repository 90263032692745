
import { defineComponent, provide, ref } from "vue";

export default defineComponent({
  name: "Stepper",
  setup(props, { slots }) {
    const defaultSlot = slots.default;
    if(defaultSlot) {
      const steps = ref([] as number[]);
      ref(defaultSlot().forEach((step, index: number) => {
        steps.value.push(index);
      }));
      const selectedIndex = ref(0);
      provide("selectedIndex", selectedIndex);
      return {
        selectedIndex,
        steps
      };
    }
    return { steps: [] };
  },
  computed: {
    step(): number {
      return this.selectedIndex || 0;
    }
  },
  data() {
    return {
      direction: "right"
    }
  },
  emits: ["change"],
  methods: {
    next() {
      if(this.step + 1 >= this.steps.length) return;
      this.direction = "right";
      this.selectedIndex = this.step + 1;
      this.$emit("change", this.step);
    },
    previous() {
      if(this.step - 1 < 0) return;
      this.direction = "left";
      this.selectedIndex = this.step - 1;
      this.$emit("change", this.step);
    },
    prev() { //Shorthand
      this.previous();
    }
  },
});
